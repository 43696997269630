h2 {
  font-size: 2em;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  line-height: 1.1;
}

p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.c-values-body {
  padding: 3rem 0;
}

.c-accordion-container {
  border-top: 5px solid lightblue;
}
.c-accordion {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.c-accordion-item {
  overflow: hidden;
  position: relative;
}

.c-accordion-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.c-accordion-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  cursor: pointer;
}

.c-accordion-icon {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.c-accordion-icon-line {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: black;
  transition: all 0.3s;
}

.c-accordion-icon-line:nth-child(2) {
  transform: rotate(90deg);
}

.c-accordion-icon.is-active > .c-accordion-icon-line:nth-child(2) {
  width: 0;
}

.c-accordion-details {
  border: 1px solid #eee;
  transition: height ease 300ms;
  height: 0;
}

.c-accordion-trigger.is-active + .c-accordion-details {
  display: block;
  height: 150px;   /*168 eskisi*/
}

.c-values-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

@media (min-width: 62rem) {
  .c-values-body {
    max-width: 66.7%;
    margin-left: 16.666667%;
    flex: 0 0 66.666667%;
  }
}

@media (max-width: 768px) {
  .c-accordion-trigger.is-active + .c-accordion-details {
    display: block;
    height: 260px;   /*312 eskisi*/
  }
}
